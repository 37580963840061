.error-page{
	.error-text {
		font-size: 9.375rem;
		line-height: 1;
		
		@include respond('phone') {
			font-size: 5rem;
		}
	}
	h4{
		font-size: 2.4rem;
		margin-bottom: 0.3125rem;
		
		@include respond('phone') {
			font-size: 1.25rem;
		}
	}
	p{
		font-size: 1rem;
		@include respond('phone') {
			font-size: 0.875rem;
		}
	}
}